<template>
  <div style="background-color: #f5f7fa; height: 100vh">
    <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="flex-shrink-0 main has-footer">
      <div>
        <div class="order-detail">
          <el-card shadow="never">
            <div class="goods-detail">
              <div class="title">
                <div class="name">
                  {{ this.orderDetail.serialNum }}
                </div>
                <div>
                  <span :style="{ color: orderDetail.statusTextColor }">
                    {{ orderDetail.statusText }}
                  </span>
                </div>
              </div>

              <div
                class="order_detail_content"
                v-if="this.orderDetail.orderFormProduct"
              >
                <div style="margin-right: 10px">
                  <van-image
                    width="100"
                    height="100"
                    :src="orderDetail.orderFormProduct.picUrl"
                  />
                </div>

                <div class="content-text">
                  <div class="name">
                    {{ this.orderDetail.orderFormProduct.productName }}
                  </div>

                  <div class="money-box">
                    <span class="money"
                      >￥{{
                        this.orderDetail.orderFormProduct.productPriceDeal
                      }}</span
                    >
                    <span class="num"
                      >{{ this.orderDetail.orderFormProduct.quantity }}件</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </el-card>

          <!-- 卡密 -->
          <div v-if="orderDetail.cardsList" style="margin-top: 10px">
            <van-cell-group>
              <div
                v-for="(value, key, index) in orderDetail.cardsList"
                :key="index"
              >
                <van-field label="卡号" :value="value.cardNum" readonly>
                  <template #button>
                    <span @click="copyText(value.cardNum)">复制</span>
                  </template>
                </van-field>

                <van-field
                  v-if="value.cardPwd"
                  label="卡密"
                  :value="value.cardPwd"
                  readonly
                >
                  <template #button>
                    <span @click="copyText(value.cardPwd)">复制</span>
                  </template>
                </van-field>

                <van-field
                  v-if="value.link"
                  label="兑换地址"
                  :value="value.link"
                  readonly
                >
                  <template #button>
                    <span @click="copyText(value.link)">复制</span>
                  </template>
                </van-field>

                <van-field
                  v-if="value.endTime"
                  label="过期时间"
                  :value="value.endTime"
                  readonly
                />
              </div>
            </van-cell-group>
          </div>

          <!---->

          <el-card shadow="never" style="margin-top: 10px">
            <div
              class="title-box"
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: left;
                padding-bottom: 20px;
              "
            >
              <div style="font-weight: 600; color: #333; line-height: 16px">
                充值信息
              </div>
            </div>
            <div class="goods-info">
              <div
                class="item"
                v-for="(item, index) in orderDetail.orderParams"
                :key="index"
              >
                <div class="label">{{ item.name }}:</div>
                <div class="item-value">
                  <!-- 图片上传 -->
                  <template v-if="item.type === '4'">
                    <el-image
                      style="width: 55px; height: 55px"
                      :src="item.value"
                    >
                    </el-image>
                  </template>

                  <span v-else> {{ item.value }} </span>
                </div>
              </div>
            </div>
          </el-card>

          <el-card shadow="never" style="margin-top: 10px">
            <div
              class="title-box"
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: left;
                padding-bottom: 20px;
              "
            >
              <div style="font-weight: 600; color: #333; line-height: 16px">
                订单信息
              </div>
            </div>
            <div class="goods-info">
              <div class="item">
                <div class="label">订单编号：</div>
                <div class="item-value">
                  {{ this.orderDetail.serialNum }}
                  <text class="item-copy">
                    <span>复制</span>
                  </text>
                </div>
              </div>
              <div class="item">
                <div class="label">订单状态：</div>
                <div class="item-value">
                  <span class="status">{{ this.orderDetail.statusText }}</span>
                </div>
              </div>

              <div class="item">
                <div class="label">支付金额：</div>
                <div class="item-value">
                  ￥{{ this.orderDetail.amountFinally }}
                </div>
              </div>

              <div class="item">
                <div class="label">购买时间：</div>
                <div class="item-value">
                  {{ this.orderDetail.datelineCreateReadable }}
                </div>
              </div>

              <div class="item">
                <div class="label">购买备注：</div>
                <div class="item-value">
                  {{ this.orderDetail.deliveryRemark }}
                </div>
              </div>

              <div class="item">
                <div class="label">充值时间：</div>
                <div class="item-value">
                  {{ this.orderDetail.datelineDeliveryReadable }}
                </div>
              </div>

              <div class="item">
                <div class="label">充值备注：</div>
                <div class="item-value">
                  {{ this.orderDetail.sellerRemark }}
                </div>
              </div>

              <van-divider>---</van-divider>

              <div class="item" v-if="orderDetail.startNum">
                <div class="label">开始数量：</div>
                <div class="item-value">
                  {{ this.orderDetail.startNum }}
                </div>
              </div>

              <div class="item" v-if="orderDetail.nowNum">
                <div class="label">当前数量：</div>
                <div class="item-value">
                  {{ this.orderDetail.nowNum }}
                </div>
              </div>
            </div>
          </el-card>
        </div>

        <!-- 商品详情 -->
        <el-card shadow="never">
          <div style="text-align: center">
            <img src="@/assets/image/shangpinxiangqing.png" width="130px" />
          </div>

          <div>
            <van-empty
              description="暂无商品描述~"
              v-if="!orderDetail.productDescription"
            />
            <div class="cap-richtext" v-else>
              <span v-html="orderDetail.productDescription"></span>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapGetters, mapState } from "vuex";
import { Toast } from "vant";
import { detail } from "@/api/order";

import "@/assets/css/style.css";
export default {
  data() {
    return {
      card: [],
      cdKey: "",
      cdKeyStatus: false,
      orderActive: 0,
      dictList: [],
      orderDetail: {},
      queryParam: {
        orderId: undefined,
      },
    };
  },
  computed: {
    // ...mapGetters({
    //   list: "productList",
    // }),
    // ...mapState({
    //   orderDetail: (state) => state.order.orderDetail,
    // }),
  },
  created() {
    document.title = "订单详情";

    //查询订单
    this.loadData();
  },
  methods: {
    loadData() {
      //订单详情
      new Promise((resolve, rejust) => {
        let data = {
          id: this.$route.query.orderId,
        };
        detail(data)
          .then((res) => {
            this.orderDetail = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    copyText(text) {
      this.$copyText(text).then(
        function (e) {
          console.log(e);
          Toast.success({
            message: "已复制到剪贴板",
          });
        },
        function (e) {
          console.log("复制失败" + e);
        }
      );
    },

    onClickLeft() {
      // this.$router.push("/order");
      this.$router.back();
    },
  },
};
</script>
<style>
.order-detail {
  /* width: 23.4375rem;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column; */
  background-color: #f5f7fa;
  padding-bottom: 0.625rem;
}

.order-detail .goods-detail {
  /* margin-top: 0.625rem; */
  /* width: 22.3125rem; */
  /* height: 15.4375rem; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.order-detail .goods-detail .title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-detail .goods-detail .title .name span {
  /* font-weight: 600; */
  color: #1e2331;
  font-size: 1.0625rem;
}
.order-detail .goods-detail .title .name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.46875rem;
}

.order-detail .goods-detail .title .name div {
  width: 1.6875rem;
  height: 1.6875rem;
  border-radius: 50%;
}

.order-detail .goods-detail .title .name text {
  font-weight: 600;
  color: #1e2331;
  font-size: 1.0625rem;
}

.order-detail .goods-detail .title .status {
  font-size: 14px;
  font-weight: 600;
  color: #fd4d20;
}

.order-detail .goods-detail .order_detail_content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 6.25rem;
  margin-top: 0.875rem;
  gap: 0.6875rem;
}

.order-detail .goods-detail .order_detail_content div {
  border-radius: 0.46875rem;
  width: 6.25rem;
  height: 6.25rem;
}

.order_detail_content .goodsImg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

/* .content img {
        user-select: none;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    } */

.order-detail .goods-detail .order_detail_content .content-text {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.order-detail .goods-detail .order_detail_content .content-text .name {
  width: 100%;
  /* max-width: 14.0625rem; */
  font-size: 14px;
  color: #1e2331;
  /* font-weight: 550; */
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  /* white-space: nowrap; */
}

.order-detail .goods-detail .order_detail_content .content-text .number {
  width: 100%;
  font-weight: 400;
  color: #9b9fa8;
  font-size: 0.8125rem;
  margin-top: 0.28125rem;
}

.order-detail .goods-detail .order_detail_content .content-text .money-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.03125rem;
}

.order-detail
  .goods-detail
  .order_detail_content
  .content-text
  .money-box
  .money {
  width: 70%;
  font-weight: 550;
  color: #1e2331;
  font-size: 0.875rem;
}

.order-detail
  .goods-detail
  .order_detail_content
  .content-text
  .money-box
  .num {
  color: #9b9fa8;
}

.order-detail .goods-detail .btn {
  /* width: 5.25rem; */
  height: 2rem;
  background: linear-gradient(90deg, #fe8754 0%, #fe5f4d 100%);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.875rem;
  align-self: flex-end;
}

.order-detail .goods-detail .total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.40625rem;
}

.order-detail .goods-detail .total .total-num {
  font-weight: 550;
  color: #fd4d20;
  font-size: 0.875rem;
}

.order-detail .goods-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.625rem;
}

.order-detail .goods-info .item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-detail .goods-info .item .label {
  color: #9b9fa8;
  font-size: 0.875rem;
}

.order-detail .goods-info .item .item-value {
  color: #1e2331;
}

.order-detail .goods-info .item .item-value .item-copy {
  color: #fd4d20;
  position: relative;
}

.cap-richtext {
  padding: 10px;
  overflow-x: hidden;
}

.cap-richtext img {
  width: 100%;
  height: auto;
  max-width: 100%;
}
</style>
