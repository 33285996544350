<template>
  <div>
    <div class="container" style="margin-top: 10px">
      <!-- <Header /> -->
      <div class="public-crumbs">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            ><a @click="toIndex()">首页</a></el-breadcrumb-item
          >

          <el-breadcrumb-item>联系客服</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span>联系客服</span>
        </div>
        <div
          class="customer_item"
          v-for="(item, index) in configList"
          :key="index"
        >
          <el-row :gutter="20" justify="center">
            <a :href="item.link" target="_blank">
              <el-col :span="2" class="center_kef" style="min-height: 100px">
                <div class="contact-info">
                  <el-image style="width: 85px" :src="item.icon"></el-image>
                </div>
              </el-col>
              <el-col :span="19" class="center_kef" style="min-height: 100px">
                <div class="contact-info">
                  <div class="contact-title">{{ item.label }}</div>
                  <div class="contact-desc">{{ item.value }}</div>
                </div>
              </el-col>
            </a>

            <el-col :span="2" class="center_kef" style="min-height: 100px">
              <div class="contact-button">
                <el-image
                  style="width: 120px"
                  :src="item.uploadImg"
                  :preview-src-list="srcList"
                  @click="handleImageClick(item.uploadImg)"
                ></el-image>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- <div class="customer_item" v-if="this.wechatNum">
          <el-row :gutter="20" justify="center">
            <el-col :span="3" class="center_kef" style="min-height: 100px">
              <div class="contact-info">
                <img
                  src="../../assets/image/wx.svg"
                  style="width: 100px; height: 100px"
                />
              </div>
            </el-col>
            <el-col :span="17" class="center_kef" style="min-height: 100px">
              <div class="contact-info">
                <div class="contact-title">官方微信</div>
                <div class="contact-desc">{{ this.wechatNum }}</div>
              </div>
            </el-col>
            <el-col :span="2" class="center_kef" style="min-height: 100px">
              <div class="contact-button">
                <el-image
                  style="width: 120px"
                  :src="wechatQrCodeImg"
                  :fit="fit"
                ></el-image>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="customer_item" v-if="this.qqNum">
          <el-row :gutter="20" justify="center">
            <el-col :span="3" class="center_kef" style="min-height: 100px">
              <div class="contact-info">
                <img
                  src="../../assets/image/qq.svg"
                  style="width: 100px; height: 100px"
                />
              </div>
            </el-col>
            <el-col :span="17" class="center_kef" style="min-height: 100px">
              <div class="contact-info">
                <div class="contact-title">官方QQ</div>
                <div class="contact-desc">{{ this.qqNum }}</div>
              </div>
            </el-col>
            <el-col :span="2" class="center_kef" style="min-height: 100px">
              <div class="contact-button">
                <el-image
                  style="width: 120px"
                  :src="qqQrCodeImg"
                  :fit="fit"
                ></el-image>
              </div>
            </el-col>
          </el-row>
        </div> -->
      </el-card>

      <!-- <section
      class="contact-address-section pt-100"
      style="background-color: #ffffff; margin-top: 50px"
    >
      <div class="container" style="bac">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4" v-if="this.contactPhone">
            <div class="box-card fluid-height">
              <div class="box-card-inner full-height">
                <div class="box-card-icon">
                  <img
                    class="box-card-img"
                    src="../../assets/image/dh.png"
                    alt="icon"
                  />
                </div>
                <div class="box-card-details">
                  <h3 class="box-card-title mb-20">联系电话</h3>
                  <p class="box-card-para">
                    {{ this.contactPhone }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4" v-if="this.contactWeChat">
            <div class="box-card fluid-height">
              <div class="box-card-inner full-height">
                <div class="box-card-icon">
                  <img
                    class="box-card-img"
                    src="../../assets/image/wx.svg"
                    alt="icon"
                  />
                </div>
                <div class="box-card-details">
                  <h3 class="box-card-title mb-20">微信</h3>
                  <p class="box-card-para">
                    {{ this.contactWeChat }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4" v-if="this.contactQQ">
            <div class="box-card fluid-height">
              <div class="box-card-inner full-height">
                <div class="box-card-icon">
                  <img
                    class="box-card-img"
                    src="../../assets/image/qq.svg"
                    alt="icon"
                  />
                </div>
                <div class="box-card-details">
                  <h3 class="box-card-title mb-20">QQ</h3>
                  <p class="box-card-para">
                    {{ this.contactQQ }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    </div>
  </div>
</template>
<script>
// import Header from "../../layout/header";
import { mapGetters, mapState } from "vuex";

export default {
  name: "contact",
  data() {
    return {
      phoneNum: "",
      qqNum: "",
      qqQrCode: null,
      qqQrCodeImg: "",
      wechatNum: "",
      wechatQrCode: null,
      wechatQrCodeImg: "",

      configList: [],
      srcList: [],
    };
  },
  components: {},
  computed: {
    ...mapGetters(["token"]),
    ...mapState({
      customerDetail: (state) => state.index.customerDetail,
    }),
  },
  created() {
    this.reload();
  },
  methods: {
    //页面加载
    reload() {
      let data = {};
      this.$store.dispatch("index/getCustomerSetting", data).then(() => {
        console.log(this.customerDetail.result);
        this.configList = this.customerDetail.result;
        // this.phoneNum = this.customerDetail.result.phoneNum;
        // this.qqNum = this.customerDetail.result.qqNum;
        // this.qqQrCode = this.customerDetail.result.qqQrCode;
        // this.qqQrCodeImg = this.customerDetail.result.qqQrCodeImg;
        // this.wechatNum = this.customerDetail.result.wechatNum;
        // this.wechatQrCode = this.customerDetail.result.wechatQrCode;
        // this.wechatQrCodeImg = this.customerDetail.result.wechatQrCodeImg;
      });
    },

    /**
     * 点击放大图片
     */
    handleImageClick(img) {
      const images = [img];
      this.srcList = images;
    },
  },
};
</script>

<style scoped>
.container_cust {
  background-color: #ffffff;
}
.pb-70 {
  padding-bottom: 70px;
}
.pt-100 {
  padding-top: 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.fluid-height {
  height: calc(100% - 30px);
  margin-bottom: 30px;
}

.box-card-inner {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 40px;
}

.box-card-title {
  text-align: center;
  margin-top: 20px;
}

.box-card-img {
  width: 150px;
}

.box-card-para {
  font-size: 20px;
}

.contact-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}
.contact-desc {
  font-size: 15px;
  color: #666;
}
.center_kef {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.contact-button {
  text-align: center;
}

.customer_item {
  padding: 15px 0px 20px 0px;
  border-bottom: 1px solid #e8eaec;
}
.customer_item:last-child {
  border-bottom: none;
}
.public-crumbs {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  /* display: flex; */
  align-items: center;
  /* box-shadow: 0px 5px 10px rgb(0 0 0 / 5%); */
}
</style>
