<template>
  <div>
    <div class="category">
      <div class="category-container-mode">
        <div class="item" v-for="(value, key, index) in catesList" :key="index">
          <div class="title">
            <span class="goods-name">{{ value.categoryName }}</span>
          </div>
          <div class="category-content">
            <div
              class="content-item"
              v-for="(goods, key, index) in value.children"
              :key="index"
              @click="toProductList(goods)"
            >
              <div class="img-box">
                <div class="el-image img">
                  <img :src="goods.prcUrl" class="el-image__inner" /><!---->
                </div>
              </div>
              <span class="content-item-name"> {{ goods.categoryName }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "category",
  props: ["module"],
  components: {},

  data() {
    return {
      catesList: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
    }),
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        // this.catesBrandList = this.producCategorytList.list_simple;
        this.catesList = this.producCategorytList.list;
      });
    },

    /**
     * 跳转到商品列表
     */
    toProductList(value) {
      this.$router.push({
        path: "pcate",
        query: { categoryId: value.id, categoryPid: value.categoryPid },
      });
    },
  },
};
</script>

<style scoped>
.category {
  width: 1350px;
  margin: 0 auto;
  margin-bottom: 36px;
}

.out-langbar[data-v-60b51efa] {
  background-color: #f6f2fa;
}

.category .category-container-mode {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 18px;
  min-height: 1000px;
  background-color: #f2f6fa;
  border-radius: 18px;
  gap: 41px;
}

.category .category-container-mode .item {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background-color: #fff;
}

.category .category-container-mode .item .title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 102px;
  border-bottom: 1px solid #edf1f4;
  padding: 0 22px;
  gap: 16px;
  position: relative;
  padding-left: 30px;
}

.category .category-container-mode .item .title .goods-name {
  color: #333;
  font-weight: 550;
  font-size: 22px;
}

.category .category-container-mode .item .title:before {
  display: block;
  content: "";
  width: 10px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 1%;
  transform: translateY(-50%);
  background: #1e9fff;
  border-radius: 10px;
}

.category .category-container-mode .item .category-content {
  width: calc(100% - 80px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 40px;
  border-radius: 0 0 20px 20px;
}

.category .category-container-mode .item .category-content .content-item {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  position: relative;
  border-bottom: 1px solid #edf1f4;
  padding: 20px 0;
  cursor: pointer;
}

.category
  .category-container-mode
  .item
  .category-content
  .content-item
  .img-box {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 184, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.category
  .category-container-mode
  .item
  .category-content
  .content-item
  .img-box
  .img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.category
  .category-container-mode
  .item
  .category-content
  .content-item
  .content-item-name {
  width: 200px;
  text-align: center;
  color: #333;
  font-size: 18px;
  cursor: pointer;
}

.category .category-container-mode .item .category-content .content-item:after {
  content: "";
  width: 1px;
  height: 69px;
  background-color: #edf1f4;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
</style>
